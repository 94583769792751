import { useState } from "react";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { displayRemoveAlert, updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/TagResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import { consoleLog } from "../../utils/log-utils";

const Tagtem = (props) => {
  const [item] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);

  const dispatch = useDispatch();
  
  
  const deleteItem = () =>{
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:item },type:"tags" }));
  }

  const editItem = () => {
    consoleLog("item",item);
    dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
  };

  const onStatusChange = async () => {
    let status = "YES";
    if (item.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: item.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        item.status = status;
        setitemStatus(status);
        consoleLog("AFTER Change", item.status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <tr key={item.id}>
      <td className="align-middle">
        <p>{item.id}</p>
      </td>
      <td className="align-middle">
        <p>{item.name}</p>
      </td>

      <td className="align-middle">
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>
      <td className="align-middle">
        <p className="col-lg-12 d-flex justify-content-center">
          <span
            className="edit-icon-btn"
            onClick={() => {
              {
                editItem();
              }
            }}
          >
            <FaRegEdit />
          </span>{" "}
          <span className="delete-icon-btn" onClick={deleteItem}>
            <FaTrash />
          </span>
        </p>
      </td>
    </tr>
  );
};

export default Tagtem;
