import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Protected from "./pages/protected-route";
import Login from "./auth/login";
import Category from "./pages/category/category";
import Products from "./pages/products/products";
import ProductAdd from "./pages/products/product-add-form";
import ProductUpdate from "./pages/products/product-update-form";
import SubCategory from "./pages/subcategory/subcategory";
import Tags from "./pages/tags/tags";
import AppVersion from "./pages/app version/appVesion";
import HomeBanners from "./pages/banners/home/home-banners";

import Payment from "./pages/payments/payment";
import Reports from "./pages/repots/reports";

import Orders from "./pages/orders/oreders";

import Stores from "./pages/store/store";

import OrderDetails from "./pages/orders/order-details";
import User from "./pages/users/user";
import UserProfile from "./pages/users/UserProfile";
import AddStore from "./pages/store/add-new-store";
import UniqueStore from "./pages/store/unique-store";
import PromoBanners from "./pages/banners/promo/promo-banner";
import CategoryBanners from "./pages/banners/categoryBanner/category-banners";
import AddHomeBanner from "./pages/banners/home/add-home-banner";
import AddCategoryBanner from "./pages/banners/categoryBanner/add-category-banner";
import Uom from "./pages/uom/uom";
import AddUser from "./pages/users/add-new-user";
import AddNewLocation from "./pages/banners/promo/add-new-location";
import UniqueStoreProducts from "./pages/store/unique store/uniqueStore-products";
import UniqueStorePayments from "./pages/store/unique store/uniqueStore-payments";
import UniqueStoreOrders from "./pages/store/unique store/uniqueStore-orders";
import ProductTagUpdate from "./pages/products/product-update-tags";
import Dashboard from "./pages/dashboard/dashboard";
import AllCoupons from "./pages/coupons/All/All-Coupons";
import LocationCoupons from "./pages/coupons/Location/location-coupons";
import StoreCoupons from "./pages/coupons/Stores/store-coupons";
import StoreCouponsItem from "./pages/coupons/Stores/store-coupon-item";
import NewCoupon from "./pages/coupons/All/add-new-coupon";
import NewStoreCoupon from "./pages/coupons/Stores/add-stores-coupon";
import NewLocationCoupon from "./pages/coupons/Location/add-location-coupon";
import DeliveryBoy from "./pages/users/delivery-boys/delivery";
import FranchiseOwner from "./pages/users/franchise-owner/franchise";
import StoreAdminItem from "./pages/users/store-admin/store-admin-item";
import StoreAdmin from "./pages/users/store-admin/store-admin";
import Customer from "./pages/users/customers/customers";
import CustomerOrders from "./pages/users/customers/customer-orders";
import CategoryTags from "./pages/category/category-tags";
import CatTags from "./pages/tags/cattags";
import StoreTags from "./pages/tags/storetag";
import UpdateStore from "./pages/store/store-update-form";
import StoreTagUpdate from "./pages/store/store-update-tags";

import UpdateStorePrice from "./pages/store/unique store/update-store-product";
import OrderAssign from "./pages/orders/order-assign";
import AppVersionTable from "./pages/app version/appVersionTable";
import StaticPage from "./pages/ui";
import FranchiseDetails from "./pages/users/franchise-owner/franchise-details";
import CouponStore from "./pages/coupons/coupon-store";
import Deliveryorder from "./pages/users/delivery-boys/delivery-orders";
import UserEdit from "./pages/users/user-edit";
import UniqueDelivery from "./pages/users/delivery-boys/unique-delivery";
import UniqueFranchise from "./pages/users/franchise-owner/unique-franchise";


import FoPayment from "./pages/payments/fo-payment";
import DbPayment from "./pages/payments/db-payment";
import StorePayment from "./pages/payments/store-payments";
import AppNotification from "./notifications/token-integration";
import FranchisePayments from "./pages/payments/fo/franchise-payments";
import FranchiseSettelements from "./pages/payments/settlements/franchise-settelements";
import UpdateTransactionId from "./pages/store/unique store/update-transactionId";
import Profile from "./pages/user-profile/profile";
import Location from "./pages/location/location";
import LocationStores from "./pages/location/location-stores";
import PaymentOverview from "./pages/payments/payment-overview";
import CustomerInfo from "./pages/users/customers/customer-info";
import SettlementPopUp from "./pages/payments/settlement-popup";
import OrderWisePayments from "./pages/store/unique store/unique store items/order-wise-payments";


export default function Routers() {
  return (
    <>
    {/* <AppNotification/> */}
    
    <Routes>
      
      <Route path="*" element={<Navigate to="/login" />} />

      <Route
        path="/categories"
        element={<Protected>{<Category />}</Protected>}
      />
      <Route path="/login" element={<Login />} />

      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/categories" element={<Category />} />

      <Route path="/ctags" element={<CategoryTags />} />

      <Route path="/products" element={<Products />} />

      <Route path="/addProduct" element={<ProductAdd />} />

      <Route path="/udprdct" element={<ProductUpdate />} />

      <Route path="/pcateg" element={<SubCategory />} />

      <Route path="/tags" element={<Tags />} />

      <Route path="/cattags" element={<CatTags />} />

      <Route path="/storetags" element={<StoreTags />} />

      <Route path="/appVersion Table" element={<AppVersionTable/>} />

      <Route path="/app version" element={<AppVersion />} />

      <Route path="/orders" element={<Orders />} />

      <Route path="/orderDetails" element={<OrderDetails />} />

      <Route path="/orderAssign" element={<OrderAssign/>}/>

      <Route path="/payments" element={<Payment />} />

      <Route path="/reports" element={<Reports />} />

      <Route path="/stores" element={<Stores />} />

      <Route path="/addStore" element={<AddStore />} />

      <Route path="/updateStore" element={<UpdateStore />} />

      <Route path="/uniqueStore" element={<UniqueStore />} />

      <Route path="/StoreProducts" element={<UniqueStoreProducts />} />

      <Route path="/updatePrice" element={<UpdateStorePrice />} />


      <Route path="/StoreOrders" element={<UniqueStoreOrders />} />

      <Route path="/StorePayments" element={<UniqueStorePayments />} />

      <Route path="/team" element={<User />} />

      <Route path="/addNewUser" element={<AddUser />} />

      <Route path="/customerOrders" element={<CustomerOrders />} />

      <Route path="/deliveryBoys" element={<DeliveryBoy />} />

      <Route path="/deliveryOrders" element={<Deliveryorder/>}/>

      <Route path="/franchiseOwner" element={<FranchiseOwner />} />

      <Route path="storeAdmins" element={<StoreAdmin />} />

      <Route path="/customers" element={<Customer />} />

      <Route path="/userProfile" element={<UserProfile />} />

      <Route path="/homeBanners" element={<HomeBanners />} />

      <Route path="/categoryBanners" element={<CategoryBanners />} />

      <Route path="/promoBanners" element={<PromoBanners />} />

      <Route path="/addHomeBanner" element={<AddHomeBanner />} />

      <Route path="addCategoryBanner" element={<AddCategoryBanner />} />

      <Route path="/addNewLocation" element={<AddNewLocation />} />

      <Route path="/uom" element={<Uom />} />

      <Route path="/ptags" element={<ProductTagUpdate />} />

      <Route path="/stags" element={<StoreTagUpdate />} />

      <Route path="/all" element={<AllCoupons />} />

      <Route path="/locationCoupons" element={<LocationCoupons />} />

      <Route path="/storecoupons" element={<StoreCoupons />} />

      <Route path="/addAllCoupon" element={<NewCoupon />} />

      <Route path="/addStoreCoupon" element={<NewStoreCoupon />} />

      <Route path="/addLocationCoupon" element={<NewLocationCoupon />} />

      <Route path="/CouponStore" element={<CouponStore/>}/>

      <Route path="/franchiseDetails" element={<FranchiseDetails/>}/>

      <Route path="/userEdit" element={<UserEdit/>}/>

      <Route path="/uniqueDelivery" element={<UniqueDelivery/>}/>

      <Route path="/uniqueFranchise" element={<UniqueFranchise/>}/>

      <Route path="/storepayment" element={<StorePayment/>}/>

      <Route path="/adminfopayments" element={<FoPayment/>}/>

      <Route path="/dbPayments" element={<DbPayment/>}/>

      <Route path="/franchisePayments" element={<FranchisePayments/>}/>

      <Route path="/franchiseSettlements" element={<FranchiseSettelements/>}/>

      <Route path="/updateTransId" element={<UpdateTransactionId/>}/>

      <Route path="/profile" element={<Profile/>}/>

      <Route path="/location" element={<Location/>}/>

      <Route path="/locationStores" element={<LocationStores/>}/>

      <Route path="/paymentsOverview" element={<PaymentOverview/>}/>

      <Route path="/customerInfo" element={<CustomerInfo/>}/>
      
      <Route path="/settlementPopUp" element={<SettlementPopUp/>}/>

      <Route path="/orderWisePayments" element={<OrderWisePayments/>}/>
      {/* <Route path="/ui" element={<StaticPage/>}/> */}
    </Routes>
    </>
  );
}
