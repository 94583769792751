import React from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { useDispatch } from 'react-redux';
import { displayRemoveAlert } from '../../../redux/slice/formUpdateSlice';
import { getImagePath } from '../../../utils/util';

function LocationItem(props) {
  const dispatch=useDispatch();
  const deleteItem = () => {
   
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:props.result },type:"banner" }));
  };
  
    return(
        <>
        <tr>
            <td>{props.id}</td>
            <td>{props.result.option_key}</td>
            <td>{props.result.option_value}</td>
            <td>{props.result.name}</td>
            
            <td><img src={getImagePath(props.result.image_path)} className="img-thumbnail" style={{width:75}} /> </td>
            
           {/* <td>
          <div className="d-flex flex-row justify-content-center form-switch form-check">
            <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked
            />
          </div>
            </td> */}
            <td className="align-middle">
                <div className="d-flex justify-content-center"> 
                
                <span className="delete-icon-btn" onClick={()=>{props.setDeleteBanner(true)}}>
                  <FaTrash onClick={deleteItem}/>
                </span>
                </div>
            </td>
            
        </tr>
        </>
    )
  
}

export default LocationItem