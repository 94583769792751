import { consoleLog } from "../../utils/log-utils";
import { CATGORY_API_PATH } from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/PostRequest";

export const listRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${CATGORY_API_PATH.LIST_PATH}`, //?sectionId=${data.secId}
      null,
      false
    ); //`${SECTION_API_PATH.LIST_PATH}?sectionId=${data.secId}`
    return result;
  } catch (error) {
    consoleLog("",error);
    // TODO Handle Error
  }
};

export const allListRequest = (data) => async () => {
  try {
    let result = await getRequest(`${CATGORY_API_PATH.LIST_PATH}`, null, false);
    return result;
  } catch (error) {
    consoleLog("",error);
    // TODO Handle Error
  }
};
export const createUpdateRequest = (data) => async () => {
  try {
    let result = await postRequest(
      CATGORY_API_PATH.CREATE_UPDATE_PATH,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const categoryTagListRequest = (data) => async () => {
  try {
    let result = await getRequest(
      `${CATGORY_API_PATH.TAG_LIST_PATH}?categoryId=${data.categoryId}`,
      null,
      false
    );
    return result;
  } catch (error) {
    consoleLog("",error);
    // TODO Handle Error
  }
};

export const updateTags = (data) => async () => {
  try {
    let result = await postRequest(
     CATGORY_API_PATH.CATEGORY_TAGS_UPDATE,
      data,
      false
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};