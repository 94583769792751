import { useEffect, useRef, useState } from "react";
import { FaEye, FaImage, FaRegEdit, FaTag, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { deleteStatus, displayLargeImage, displayRemoveAlert, updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/CategoryResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";
import noImage from "../../assets/no_image.jpg";
import {
  CategoryImageUplaodRequest,
  categoryImageUplaodRequest,
  subCategoryImageUplaodRequest,
} from "../../network/service/ImageUploadService";
import { useNavigate } from "react-router-dom";
import { isCms, isFranchisLogin } from "../../utils/userRoles";
import { API_CONFIG } from "../../network/config/ApiConfig";
import { getImagePath } from "../../utils/util";
import { consoleLog } from "../../utils/log-utils";

const CategoryItem = (props) => {
  const [product, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(product.status);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [file, setFile] = useState();
  const hiddenFileInput = useRef(null);

  const editItem = () => {
   
    consoleLog("item",product)
    dispatch(updateFormInfo({ displayForm: true, formData: { item: product } }));
  };

  const deleteItem = () => {
    
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item: product } }));
  };

  const editTags = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: { item: product } }));
    navigate("/ctags");
  };


  const onStatusChange = async () => {
    let status = "YES";
    if (product.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: product.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(product, (product.status = status));
        setitemStatus(status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  const onImageClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null) {
      setFile(URL.createObjectURL(fileUploaded));
      uploadImage(fileUploaded);
    }
  };

  const uploadImage = async (file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: product.id,
      fileData: form_data,
    };
    await dispatch(categoryImageUplaodRequest(requestBody)).then(
      (response) => { }
    );
  };
  const showLargeImage = () => {
    dispatch(
      displayLargeImage({ displayLargeImage: true, formData: { product } })
    );
  };
  const onNgImageClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <tr key={product.id}>
      {/* <th scope="row">{od.id}</th> */}
      <td className="align-middle">
        <p>{product.id}</p>
      </td>
      <td className="align-middle">
        <div className="cat_mainwrap">
          {
            product.image_path === "" ? null :
              
               <div className="cat_btnwrap">
              <FaRegEdit className="faWhite" onClick={() => onNgImageClick()} />

              <FaEye className="faWhite" onClick={() => showLargeImage()} />
            </div>
             
          }
          <input
            type="file"
            onChange={handleImageInputChange}
            ref={hiddenFileInput}
            style={{ display: "none" }} // Make the file input element invisible
          />
          {file ? (
            <>
              <img
                src={file}
                alt={product.name}
                className="img-thumbnail"
                width="50px"
                height="50px"
              />
            </>
          ) : product.image_path !== "" ? (
            <img
              src={getImagePath(product.image_path)}
              alt={product.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          ) : (
            <img
              src={noImage}
              alt={product.name}
              className="img-thumbnail"
              width="50px"
              height="50px"
            />
          )}
        </div>
      </td>
      <td className="align-middle">
        <p>{product.name}</p>
      </td>


      {
      !isFranchisLogin()&& <>
          <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                onChange={onStatusChange}
                checked={itemStatus === "YES" ? true : false}
              />
            </div>
          </td>
          <td className="align-middle">
            <p className="col-lg-12 d-flex justify-content-center">
              <span
                className="edit-icon-btn"
                onClick={() => {
                  editItem();
                }}
              >
                <FaRegEdit />
              </span>{" "}
              <span className="delete-icon-btn" onClick={() => {
                deleteItem();
              }}>
                <FaTrash />
              </span>
              {/* <span
                className="delete-icon-btn tag-icon-btn"
                onClick={() => {
                  editTags();
                }}
              >
                <FaTag />
              </span> */}
            </p>
          </td>
        </>
      }

    </tr>
  );
};

export default CategoryItem;
