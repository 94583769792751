import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { consoleLog } from "../../../../utils/log-utils";
import { isFranchisLogin } from "../../../../utils/userRoles";

function OrderItem(props) {
  const [detail] = useState(props.item);
  consoleLog("item...",props.item)
  
  consoleLog("detail...",detail)
  const navigate = useNavigate();
  function Temp(status) {
    switch (status) {
      case "0":
        return <span style={{ color: "black" }}>Pending</span>;

      case "1":
        return <span style={{ color: "pinkAccent" }}>Accepted</span>;

      case "2":
        return <span style={{ color: "blueAccent" }}>Assigned</span>;

      case "3":
        return <span style={{ color: "yellow" }}>Picked Up</span>;
      case "4":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "5":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "6":
        return <span style={{ color: "red" }}>Cancelled</span>;
      case "7":
        return <span style={{ color: "black" }}>Rejected</span>;
      case "8":
        return (
          <span style={{ color: "0xffcc0d0d" }}>Order Cancel Request</span>
        );
    }
  }

  const orderDetailFynctionality = () => {
    //dispatch(updateFormInfo({ displayForm: false, formData: { order } }));

    navigate("/orderDetails", { state: { order: props.item, from: "store" } });
  };

  return (
    <tr>
      <td>{props.sno}</td>
      <td>{props.item.created_at}</td>
      {/* .substring(0, 10) */}
      {
        isFranchisLogin()?<td>
        #EP0{props.item.order_id}
      </td>:""
      }
{
  !isFranchisLogin()?<td onClick={() => orderDetailFynctionality()}>
  <Link className="anchor-color-change">#EP0{props.item.order_id}</Link>
</td>:""
}
      
      <td>₹{props.item.total_price}</td>
      <td>{props.item.customerName}</td>
      {
        !isFranchisLogin()? <td>{props.item.phone}</td>:""
      }
      
      
      
      <td>{props.item.landmark}</td>
      <td>{props.item.coupon_code}</td>
      <td>{Temp(props.item.status)}</td>
      <td>{props.item.dbName}</td>
    </tr>
  );
}

export default OrderItem;
