import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formUpdateData,
  formUpdateStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { useNavigate } from "react-router-dom";
import { updateMessage } from "../../redux/slice/toastSlice";
import AppToast from "../../components/appToast";

import {
  onClearData,
  selectedItemsStatus,
} from "../../redux/slice/selectedItemsSlice";
import {
  storeTaglistAction,
  updateStoreTagsAction,
} from "../../network/store/action/TagResponseAction";
import SelectableStoreTagItem from "./selectable-store-tag-item";
import { consoleLog } from "../../utils/log-utils";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { TAG_API_PATH } from "../../network/config/apiPaths";

function StoreTagUpdate() {
  // to get api data

  const dispatch = useDispatch();
  const showRequestForm = useSelector(formUpdateStatus);
  const formData = useSelector(formUpdateData);
  consoleLog("formData",formData);
  const itemInfo = formData.product;
  // list of items
  const [resultList, setResultList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [storeId, setStoreId] = useState("");
  const selectedIds = useSelector(selectedItemsStatus);
  const navigate = useNavigate();

  useEffect(() => {
    if (itemInfo != undefined) {
      setStoreId(itemInfo.id);
      loadData(itemInfo.id);
    }
  }, []);

  // useEffect(() => {

  // }, [productId]);

  const loadData = async (storeId) => {
    setLoading(true);
    await dispatch(storeTaglistAction({ storeId: storeId }, dispatch)).then(
      (response) => {
        setLoading(false);
        if (response.result) {
          setResultList(response.result);
        }
      }
    );
  };

  const onUpdateClick = async () => {
    if (selectedIds && selectedIds.length > 0) {
      let itemObj = {
        storeId: storeId,
        tagIds: selectedIds.toString(),
      };

      await dispatch(updateStoreTagsAction(itemObj, dispatch)).then(
        (response) => {
          consoleLog("createUpdateAction", response.result);
          if (response.result) {
            dispatch(
              updateMessage({
                display: true,
                message: "Tags Updated.",
              })
            );
            dispatch(onClearData());
            navigate(-1);
          }
        }
      );
    } else {
      dispatch(
        updateMessage({
          display: true,
          message: "Select Tags",
        })
      );
    }
  };
  const clearTags = async () => {
    if (selectedIds && selectedIds.length > 0) {
      let itemObj = {
        storeId: storeId,
        
      };
      let data=await axios.post(API_CONFIG.API_HOST+TAG_API_PATH.STORE_TAGS_DELETE,itemObj,{
        headers:API_HEADERS
      })
      navigate(-1)
      
    } else {
      dispatch(
        updateMessage({
          display: true,
          message: "No Tags",
        })
      );
    }
  };
  return (
    <>
      <AppToast />
      <main className="container-fluid dashboard">
        <div
          className="row justify-content-around tble"
          style={{ padding: "20px" }}
        >
          <div className="col-6">
            <h3 className="dashboard-title">Store Tags</h3>
          </div>
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn btn-purple-bg"
              onClick={() => {
                onUpdateClick();
              }}
            >
              Update
            </button>

            <button
              type="button"
              className="mr-auto btn "
              onClick={() => {
                clearTags();
              }}
            >
              Clear Tags
            </button>
          </div>
        </div>

        {resultList && resultList.length > 0 ? (
          <div className="col-md-12" style={{ padding: "20px" }}>
            {resultList.map((item, i) => {
              return (
                <SelectableStoreTagItem
                  item={item}
                  key={item.id}
                  itemStatus={item.storeTagId === "NO" ? false : true}
                />
              );
            })}
          </div>
        ) : isLoading ? (
          <p>Loading Tags</p>
        ) : (
          <p>No Data found</p>
        )}
      </main>
    </>
  );
}
export default StoreTagUpdate;
