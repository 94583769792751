import { useState } from "react";
import { useNavigate } from "react-router-dom";

function StorePaymentItem(props){
    const[data]=useState(props.item)
    const navigate=useNavigate();
    const storeSettlement=()=>{
        navigate("/settlementPopUp")
    }
    return(
        <>
        <tr>
            <td>{props.sno}</td>
            <td>{data.storeName}</td>
            <td> {data.dated}</td>
            <td>{data.orders_count}</td>
            <td>{data.orders_amount}</td>
            <td>{data.store_payment}</td>
            <td>{data.transaction_date }</td>
            <td>{data.transaction_id===""?"-----":data.transaction_id}</td>
            <td>{data.payment_status==="PENDING"?<button className="btn dashboard-div-bg" onClick={storeSettlement}>Update</button>:data.payment_status}</td>
        </tr>
        </>
    )
}
export default StorePaymentItem;