import React, { useEffect, useState } from 'react'



import axios from 'axios';
import FoPaymentItem from './fo-payment-item';
import { API_CONFIG, API_HEADERS } from '../../network/config/ApiConfig';
import { LOCATION_API_PATH, PAYMENTS_API_PATH, USER_API_PATH } from '../../network/config/apiPaths';
import { consoleLog } from '../../utils/log-utils';
import Select from "react-select";

function FoPayment(props) {
  const[isLoading,setIsLoading]=useState(false)
  const[paymentList,setPaymentList]=useState([]);
  const[selectMonth,setMonth]=useState("");
  const [locationList,setLocationList]=useState([])
  const[filterForm,setFilterForm]=useState({
    fo_name:""
  })
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedFo, setSelectedFo] = useState(null);

  const [foList,setFoList]=useState([])

  const month=[
    {id:1,name:"JAN"},
    {id:2,name:"FEB"},
    {id:3,name:"MARCH"},
    {id:4,name:"APRIL"},
    {id:5,name:"MAY"},
    {id:6,name:"JUNE"},
    {id:7,name:"JULY"},
    {id:8,name:"AUG"},
    {id:9,name:"SEP"},
    {id:10,name:"OCT"},
    {id:11,name:"NOV"},
    {id:12,name:"DEC"},
  ]

  useEffect(()=>{
    paymentApiCall();
    fetchLocation();
    
  },[selectedFo])
  useEffect(()=>{
    fetchFoList()
  },[selectedLocation])

  const paymentApiCall = async () => {
    let data = await axios.get(
      API_CONFIG.API_HOST +
        PAYMENTS_API_PATH.ADMIN_FO_PAYMENTS+

        `?foId=${selectedFo !== null ? selectedFo.id : ""}&type=fo`,
     
      {
        headers: API_HEADERS,
      }
    );
    consoleLog("data", data.data.result);
    setPaymentList(data.data.result);
  };
  const fetchLocation = async () => {
    let data = await axios.get(
      API_CONFIG.API_HOST + LOCATION_API_PATH.LOCATION_LIST_PATH,
      {
        headers: API_HEADERS,
      }
    );
    setLocationList(data.data.result);
    consoleLog("setLocationList", data.data.result);
  };
  const handleChanges=(e)=>{
  setFilterForm({
  ...filterForm,
  [e.target.name]:e.target.value
})
  }

  async function fetchFoList() {
    if (selectedLocation !== null) {
      let apiPath =
        API_CONFIG.API_HOST +
        `user/getNearbyUsers?lat=${selectedLocation.latitude}&lng=${selectedLocation.longitude}&roleId=9`;

      const data = await axios.get(apiPath, {
        headers: API_HEADERS,
      });

      // setIsLoading(false);
      consoleLog("FoResult", data.data.result);

      setFoList(data.data.result);
      // setResultList(data.data.result);
    }
  }

  
  return (
    <>
     {/* <div className='row mb-3'>
        <div className='col-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control'>
            <option>{"--select month--"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className='col-2'>

          <label>From</label>
          <input type="date" className='form-control'/>
            
          
        </div>
        <div className='col-2'>

          <label>To</label>
          <input type="date" className='form-control'/>
            
          
        </div>
       </div> */}
    {/* <div className="row mb-3 ">
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
             100 <br/> Orders Recieved
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      100 <br/> Orders Accepted
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      50 <br/> Orders Shipped
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      30 <br/> Orders Delivered
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      0 <br/> Orders Cancelled
      </div>
    </div>
  </div> */}
  <main className="container-fluid dashboard">
    <div className='row m-3'>
      <div className='col-12 mb-3'>
         <h4>Franchise Payments</h4>
      </div>
      <div className="col-md-2">
            <div className="form-group">
              <label>Location</label>

              <Select
                options={locationList}
                value={selectedLocation !== "" ? selectedLocation : ""}
                getOptionLabel={(option) => `${option.location_name}`}
                getOptionValue={(option) => `${option.location_name}`}
                onChange={(sOption) => {
                  setSelectedLocation(sOption);
                }}
                // isMulti
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>FO</label>

              
              <Select
              options={foList}
              value={selectedFo !== "" ? selectedFo : ""}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              onChange={(sOption) => {
                setSelectedFo(sOption);
              }}
              />
            </div>
          </div>
    <div className="col-12 card-1 h-scroll">
    <table className="table table-hover align-middle">
      <thead className="thead-dark">
        <tr>
        <th scope="col">FO Name</th>
        <th scope="col">Date</th>
          
          {/* <th scope="col">Order Id</th> */}
          <th scope="col">Order Amount</th>
          {/* <th scope="col">Total Orders</th> */}
          <th scope="col">Store Payment</th>
          <th scope="col">App Share</th>
          <th scope="col">Delivery Charges</th>
          <th scope="col">Package Charges</th>
          <th scope="col">Platform Fee</th>
          <th scope="col">Coupon Cost</th>
          
          <th scope="col">FO Amount</th>
          <th scope="col">Final App Share</th>
        </tr>
      </thead>
      
        <tbody>
          
          {
                  paymentList !==null ? paymentList.length>0&&
                  paymentList.map((item,i)=>{
                    return(
                      <>
                      <FoPaymentItem item={item} sno={i+1}/>
                      </>
                    )
                  }):<>No data found</>
                  }
        </tbody>
      
    </table>
  </div>
  </div>
  </main>
  </>
  )
}

export default FoPayment