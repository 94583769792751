import { createSlice } from "@reduxjs/toolkit";

export const formUpdateSlice = createSlice({
  name: "formUpdate",
  initialState: {
    displayForm: false,
    displayRemove: false,
    type: "",
    imageUpload: false,
    formData: {},
    displayLargeImage: false,
  },
  reducers: {
    updateFormInfo: (state, data) => {
      console.log("payload updateFormInfo", data.payload.formData);
      state.formData = data.payload.formData;
      state.displayForm = data.payload.displayForm;
      state.imageUpload = data.payload.imageUpload;
    },

    displayForm: (state, data) => {
      console.log("payload displayForm", data.payload.formData);
      state.displayForm = data.payload.displayForm;
    },
    displayRemoveAlert: (state, data) => {
      console.log("payload displayRemoveAlert", data.payload.formData);
      state.formData = data.payload.formData;
      state.displayRemove = data.payload.displayRemove;
      state.type = data.payload.type;
    },

    displayLargeImage: (state, data) => {
      console.log("payload displayLargeImage", data.payload);

      state.displayLargeImage = data.payload.displayLargeImage;
      state.formData = data.payload.formData;
    },
  },
});

export const {
  updateFormInfo,
  displayForm,
  displayRemoveAlert,
  displayLargeImage,
} = formUpdateSlice.actions;

export const formUpdateData = (state) =>
  state.rootReducer.formUpdateReducer.formData;

export const formUpdateStatus = (state) =>
  state.rootReducer.formUpdateReducer.displayForm;

export const imageUploadStatus = (state) =>
  state.rootReducer.formUpdateReducer.imageUpload;

export const deleteStatus = (state) =>
  state.rootReducer.formUpdateReducer.displayRemove;

export const largeImageStatus = (state) =>
  state.rootReducer.formUpdateReducer.displayLargeImage;

export const itemType = (state) => state.rootReducer.formUpdateReducer.type;

export default formUpdateSlice.reducer;
