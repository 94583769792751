import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatedDate } from "../../../../utils/date-utils";

function OrderWisePaymentItem(props){
    const[data]=useState(props.item)
    const navigate=useNavigate();
    const  navigatePaymentsDetailPage=()=>{
        navigate("/franchisePayments",{state:{storeId:data.storeId}})
    }
    return(
        <>
        <tr>
        {/* <td>{data.order_id}</td> */}
       
        {/* <td>#Svr210524</td> */}
        <td>
          {data.duration}
          </td>
        {/* <td onClick={()=>storeInfoPage()}>
          <Link>{data.storeName}</Link>
        </td> */}
        {/* <td>{data.orders_count}</td> */}
        <td>₹{data.orderPayment}</td>
        
        
        <td>₹{data.storePayment}</td>
        <td>₹{data.appPayment }</td>
        <td>₹{data.deliveryCharges}</td>
        <td>₹{data.packageCharge}</td>

<td>₹{data.platformFee}</td>
<td>₹{data.couponCost}</td>
<td>₹{data.finalPayShare}</td>
{/* ({data.final_app_amount}) */}
<td>₹{data.foPayment}</td>
{/* <td>{data.payment_status}</td> */}

        
        
        {/* <td>{data.transaction_date}</td>
        <td>{data.transaction_id}</td> */}
      </tr>
        </>
    )
}
export default OrderWisePaymentItem;