import { useEffect, useState } from "react";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { consoleLog } from "../../utils/log-utils";
import axios from "axios";
import StoreItem from "../store/store-item";
import { useLocation } from "react-router-dom";
import LocationStoreItem from "./location-store-item";
import { isFranchisLogin } from "../../utils/userRoles";

function LocationStores(){
    const [storeList,setStoreList]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const location=useLocation();
    const latitude=location.state!==null?location.state.latitude:"";
    const longitude=location.state!==null?location.state.longitude:"";
    const locationName=location.state!==null?location.state.name:"";
    useEffect(() => {
        StoreList();
        
      }, []);
    
      async function StoreList() {
        setIsLoading(true);
    
        let apiPath = ""
        if (isFranchisLogin()) {
          apiPath = API_CONFIG.API_HOST + `Store/nearbyStores?latitude=${latitude}&longitude=${longitude}&foId=${localStorage.getItem("userId")}`;

        }
     else{
      apiPath = API_CONFIG.API_HOST + `Store/nearbyStores?latitude=${latitude}&longitude=${longitude}`;

     }
        const data = await axios.get(apiPath, {
          headers: API_HEADERS,
        });
    
        setIsLoading(false);
        // if (data.data.result !== null) {
        //   setStoreResult(data.data.result);
        //   setResultList(data.data.result);
        // }
        consoleLog(data.data.result);
        setStoreList(data.data.result)
      }
      return(
        <>
         <main className="container-fluid dashboard">
        <div className="row m-3">
            <div className="col-12 mb-3">
                <h4>{locationName}</h4>
            </div>
          <div className="col-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Store ID</th>
                  <th Scope="col">Store Category</th>
                  <th scope="col">Store Name</th>
                  <th scope="col">Location</th>
                  <th scope="col">city</th>
                  <th scope="col">Contact Name</th>
                  <th scope="col">FO Name</th>

                  <th scope="col">Contact No</th>

                  <th scope="col">Radius</th>
                 
                </tr>
              </thead>

              <tbody>
                {isLoading ? (
                  <>Loading</>
                ) : storeList && storeList.length >= 0 ? (
                  <>
                    {storeList.map((obj, i) => {
                      return <LocationStoreItem item={obj} key={obj.id} sno={i+1}/>;
                    })}
                  </>
                ) : (
                  <>No data</>
                )}
              </tbody>
            </table>
          </div>
          {/* <div className="col-12">
            <ul className="pagination">
              {
                numbers.map((n,i)=>{
                  <li className="page-item" key={i}>
                    <a href='#' className="page-link" onClick={changeCpage}>{n}</a>
                  </li>
                })
              }
            </ul>
          </div> */}
        </div>
        </main>
        </>
      )

}
export default LocationStores