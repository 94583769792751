import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function PaymentOverviewItem(props){
    const[data]=useState(props.item)
    const navigate=useNavigate();
    const  navigatePaymentsDetailPage=()=>{
        navigate("/franchisePayments",{state:{storeId:data.storeId}})
    }
    return(
        <>
        <tr>
            <td>{props.sno}</td>
            <td onClick={navigatePaymentsDetailPage}>
                <Link>{data.storeName}</Link>
                </td>
            {/* <td> {data.dated}</td> */}
            <td>{data.ordersCount}</td>
            <td>₹{data.totalAmount}</td>
            <td>₹{data.foPayment}</td>
            
        </tr>
        </>
    )
}
export default PaymentOverviewItem;