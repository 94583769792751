import React, { useEffect, useState } from "react";

import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../../../network/config/ApiConfig";
import { PAYMENTS_API_PATH } from "../../../../network/config/apiPaths";
import { consoleLog } from "../../../../utils/log-utils";
import OrderWisePaymentItem from "./order-wise-payments-item";


function OrderWisePayments(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [selectMonth, setMonth] = useState("");
  const storeId=props.id;
  consoleLog("storeId",storeId)
  const month = [
    { id: 1, name: "JAN" },
    { id: 2, name: "FEB" },
    { id: 3, name: "MARCH" },
    { id: 4, name: "APRIL" },
    { id: 5, name: "MAY" },
    { id: 6, name: "JUNE" },
    { id: 7, name: "JULY" },
    { id: 8, name: "AUG" },
    { id: 9, name: "SEP" },
    { id: 10, name: "OCT" },
    { id: 11, name: "NOV" },
    { id: 12, name: "DEC" },
  ];

  useEffect(() => {
    paymentApiCall();
  }, []);

  const paymentApiCall = async () => {
    let data = await axios.get(
      API_CONFIG.API_HOST +
        `Payments/list?type=store&storeId=${storeId}&fromDate=&toDate=`,
      {
        headers: API_HEADERS,
      }
    );
    consoleLog("orderWisePayments", data.data.result);
    setPaymentList(data.data.result);
  };

  return (
    <>
      {/* <div className='row mb-3'>
        <div className='col-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control'>
            <option>{"--select month--"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className='col-2'>

          <label>From</label>
          <input type="date" className='form-control'/>
            
          
        </div>
        <div className='col-2'>

          <label>To</label>
          <input type="date" className='form-control'/>
            
          
        </div>
       </div> */}
      {/* <div className="row mb-3 ">
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
             100 <br/> Orders Recieved
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      100 <br/> Orders Accepted
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      50 <br/> Orders Shipped
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      30 <br/> Orders Delivered
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      0 <br/> Orders Cancelled
      </div>
    </div>
  </div> */}
      
        <div className="row">
        <div className="col-6 mb-3">
              <h3 className="dashboard-title">Payments</h3>
            </div>
          <div className="col-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
              <tr>
                  {/* <th scope="col">Order Id</th> */}

                  {/* <th scope="col">Settlement ID</th> */}
                  <th scope="col">Date</th>
                  {/* <th Scope="col">Store Name</th> */}
                  {/* <th scope="col">Total Orders</th> */}
                  <th scope="col">Order Amount</th>
                  <th scope="col">Store Amount</th>

                  <th scope="col">App Share</th>
                  <th scope="col">Delivery Charges</th>
                  <th scope="col">Package Charges</th>
                  <th scope="col">Platform Fee</th>
                  <th scope="col">Coupon Cost</th>
                  <th scope="col">Bal. App Amount</th>
                  <th scope="col">FO Amount</th>
                  {/* <th scope="col">Status</th> */}

                  {/* <th scope="col">Date</th>
                  <th scope="col">Transaction ID</th> */}
                </tr>
              </thead>

              <tbody>
                {paymentList !== null ? (
                  paymentList.length > 0 &&
                  paymentList.map((item, i) => {
                    return (
                      <>
                        <OrderWisePaymentItem item={item} key={i} sno={i+1} />
                      </>
                    );
                  })
                ) : (
                  <>No data found</>
                )}
              </tbody>
            </table>
          </div>
        </div>
      
    </>
  );
}

export default OrderWisePayments;
