import React, { useEffect, useState } from "react";

import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";

import { API_CONFIG, API_HEADERS } from "../../../network/config/ApiConfig";
import { ORDER_API_PATH, USER_API_PATH } from "../../../network/config/apiPaths";
import CustomerOrderItem from "./customer-order-item";
import { consoleLog } from "../../../utils/log-utils";
import { isAdmin, isFranchisLogin } from "../../../utils/userRoles";


function CustomerOrders(props) {
  const navigate=useNavigate();
  const location=useLocation();
  const id=props.id;
  

  const city=location.state!==null?location.state.city:"";

  const [apiResult, setApiResult] = useState([]);

  const [searchVal, setSearchVal] = useState("");
 
  const [filterList, setFilterList] = useState([]);

  

  useEffect(() => {
    UserList();
  }, []);

  const UserList = async () => {
    const data = await axios.get(
      API_CONFIG.API_HOST + ORDER_API_PATH.ORDER_LIST+"?userId="+id,
      {
        headers: API_HEADERS,
      }
    );
    consoleLog(data.data.result)
    setApiResult(data.data.result);
    setFilterList(data.data.result)
  };

  

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    consoleLog("search Value", searchTerm);
      const filterBySearch = apiResult.filter((item) => {
        if (item.phone.includes(searchTerm)) {
          return item;
        }
      });
      setFilterList(filterBySearch);
   
  }

 
  
  return (
   
    
      <div className="row m-3">
       
        
        <div className="col-12 card-1 h-scroll">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sno</th>
                <th scope="col">Date & Time</th>
                <th scope="col">Order No</th>
                <th scope="col">Order Amount</th>
                {/* <th scope="col">Recievers Name</th> */}
                <th scope="col">D.Location</th>
                <th scope="col">Coupon</th>
                <th scope="col">Status</th>
                {/* {
                  isFranchisLogin()?"":<th scope="col">Actions</th>
                } */}
                
              </tr>
            </thead>

            <tbody>
              {/* {filterList&& filterList.length>0&&filterList.map((obj) => {
                
              })} */}
              {filterList !== null ? (
                  filterList.length > 0 &&
                  filterList.map((obj, i) => {
                    return (
                      <>
                       return <CustomerOrderItem {...obj} key={obj.id} />;
                      </>
                    );
                  })
                ) : (
                  <>No data found</>
                )}
            </tbody>
          </table>
        </div>
      </div>
   
  );
}

export default CustomerOrders;